import React, { ComponentType, PropsWithChildren, useEffect, useState } from "react";
import { GridLayout } from "../../common/types/Data/GridLayout";
import gridLayoutData from "../data/grid-layout.json";

export type WithGridLayout = {
    gridLayout: GridLayout;
};

type GridLayoutData = {
    grid: GridLayout;
};

export const withGridLayout = <TProps,>(Component: ComponentType<TProps & WithGridLayout>): ComponentType<TProps> => {
    const WithGridLayout: ComponentType<TProps> = (props: PropsWithChildren<TProps>) => {
        const [gridLayout, setGridLayout] = useState<GridLayout>({
            desktopGridTemplate: `0`,
            mobileGridTemplate: `0`,
            tabletGridTemplate: `0`,
            gridId: "unloaded",
        });

        useEffect(() => {
            // No need to load from backend any more
            setGridLayout((gridLayoutData as GridLayoutData).grid);
        }, []);

        return <Component {...props} gridLayout={gridLayout} />;
    };

    return WithGridLayout;
};
